/* Webfont: StoutHeavy */
@font-face {
    font-family: 'StoutHeavy';
    src:
        url('../public/fonts/StoutHeavy.eot'),
        /* IE9 Compat Modes */ url('../public/fonts/StoutHeavy.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../public/fonts/StoutHeavy.woff') format('woff'),
        /* Modern Browsers */ url('../public/fonts/StoutHeavy.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../public/fonts/StoutHeavy.svg#StoutHeavy') format('svg'); /* Legacy iOS */
    font-display: block;
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'FuturaPt';
    src:
        url('../public/fonts/FuturaPt.woff') format('woff'),
        url('../public/fonts/FuturaPt.woff2') format('woff2');
    font-display: block;
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

.stout-font-class {
    font-family: 'StoutHeavy';
}

.future-font-class {
    font-family: 'FuturaPt';
}

.PhoneInputInput,
.PhoneInputInput:focus {
    border: none;
    outline: none;
}

@media (max-width: 767px) {
    .footer-social-icons {
        width: 100%;
    }
}
