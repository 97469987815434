.hotel-base-button-overwrite {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .login-form-wrapper {
    width: 470px;
  }
  .hotel-base-button-overwrite {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }
}

.contact-container .form-select {
  background-image: url(/static/img/icon/arrow-down-gray.svg) !important;
  background-repeat: no-repeat;
  background-position: right 6px center !important;
  background-size: 30px !important; }

#experience-slickbox .slick-track, #feature-slickbox .slick-track{
  margin-left: 0;
}
.select-group-modal .quantity .quantity-input::selection {
  background: none;
}

@media (max-width: 1200px) {
  .featured-container .slick-prev {
    position: inherit;
    width: inherit;
  }
  .featured-container .slick-next {
    position: inherit;
    width: inherit;
  }
}

.no-found .footer{
  display: none !important;
}

/* .mobile-mb2 .footer-pb {
  padding-bottom: 73px;
}

.pc-mb2 .footer-pb {
  padding-bottom: 73px;
} */

.tips-limit {
  max-height: calc(100vh - 170px);
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  .tips-limit {
    max-height: calc(100vh - 105px);
  }
}

.css-1sugtjn, [data-css-1sugtjn] {
  z-index: 1100 !important;
}

.ticketType-tooltip {
  z-index: 2;
}

.page-loading-height {
  height: calc(100vh - 386px - 72px);
}

.my-account-body .over-line-height {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
}

.my-account-body .payment-card-list {
  line-height: 0;
}

@media (max-width: 767px){
  .my-account-body .payment-card-list {
    margin-top: 16px;
  }
}

@media (min-width: 768px) {
  .expand-width {
    width: 90%;
  }
  .fixed-button-width{
    width: 360px;
    margin: 0 auto;
  }
}

.modal-background {
  background: rgba(29, 41, 57, 0.5);
}

.navbar-header-image {
  width: 32px !important;
  height: 32px !important;
  min-width: auto !important;
  min-height: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

/* my-account */
/* .my-account .modal-body{
  font-size: 14px;
} */
.my-account .ff-bold {
  font-weight: 800;
}

/* .my-account .modal-header {
  padding: 24px;
} */

.my-account .modal-body {
  /* padding: 24px; */
  color: #403d3e;
  font-size: 14px;
}

/* .my-account .modal-dialog {
  width: 455px;
} */

/* .my-account .modal-header .close {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  opacity: 1;
  line-height: 24px;
  font-family: Arial;
  text-shadow: none;
} */

/* @media (max-width: 767px){
  .my-account .modal-header {
    padding: 24px 16px;
  }
  .my-account .modal-dialog {
    width: 359px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
  }
}

@media (min-width: 768px){
  .my-account .modal-dialog {
    top: 20%;
  }
} */

.my-account .mt-n3  {
  margin-top: -1rem;
}

.login-wrapper .text-forgot {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.62px;
  color: #403d3e;
  text-align: center;
}

.login-wrapper .nsm7Bb-HzV7m-LgbsSe {
  border-radius: 8px;
}

@media (max-width: 768px){
  .reseat-modal .modal-dialog {
      margin-top: 80px;
  }
  .login-wrapper .text-forgot {
    font-size: 12px;
  }
}

.mb-1-2 {
  margin-bottom: 1.5rem !important;
}

.mb-3-2 {
  margin-bottom: 3rem !important;
}

@media screen and (min-width: 1200px) {
  .center-up-and-down {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

.mobile-text-align {
  text-align: center;
}

.update-payment-method-content {
  padding-top: 64px;
}

@media (max-width: 767px) {
  .update-payment-method-content {
    padding-top: 1.5rem;
  }
}

@media (min-width: 768px) {
  .update-payment-method .fz-23 {
    font-size: 23px;
  }

}


/* event detail ticket tips Popup*/
.ticket-tips .card-text:last-child {
  margin-bottom: 1.5rem;
}


/* contact us */
.contact-container .reset-pr {
  padding-right: 24px;
}

.contact-container .related-sort-down {
  font-size: 24px;
  position: absolute;
  right: 8px;
  top: 6px;
  display: block;
  pointer-events: none;
}

.p-2-1 {
  padding: 0.6rem !important;
}

.m-2-1 {
  margin: 0.6rem !important;
}

.user-tips {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.mt-b-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.space-pre-line{
  white-space: pre-line;
}
/* .event-detail-wrapper .room-property-price {
  white-space: pre-line;
} */

.accordion {
  border: none !important;
}

.accordion-button {
  font-weight: 500;
}

.countDown-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1030;
  transition: all 0.4s ease-in-out;
}

/* index2 */
.featured-container .card .card-img-wrapper {
  padding-bottom: 100% !important;
}


.my-experience-container .js-page-loading {
  margin-top: 121px;
}

@media (max-width: 767px) {
  .my-experience-container .js-page-loading {
    margin-top: 98px;
  }
}

/* carerrs */
@media (min-width: 48em) and (max-width: 60.5625em){
  [data-size*="m-6"] {
      width: 50%;
  }
}

.ml-2 {
  margin-left: 2rem;
}

.transparent-page-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 3000;
  height: 32px;
  width: 64px;
  margin-left: -32px;
  margin-top: -64px;
}

.fixed-loading {
  position: fixed !important;
}

.navbar-mobile .navbar .mine-portrait{
  -webkit-appearance: none;
}

@media screen and (min-width: 768px) {
  .h1-fs {
    font-size: 54px !important;
  }
}

.bg-grey {
  background-color: #F9FAFB;
}

.fw-700 {
  font-weight: 700;
}

.mt-12 {
  margin-top: 12px !important;
}
.mt-40{
  margin-top: 40px !important;
}
.mt-32{
  margin-top: 32px !important;
}
/*count down*/
@media (min-width: 768px) {
  .count-down-wrapper {
    height: 48px;
  }
}

@media (max-width: 767px) {
  .count-down-wrapper {
    height: 30px;
  }
}

.count-down {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1025;
  transition: all 0.4s ease-in-out;
}

@media (min-width: 768px) {
  .count-down {
    transform: translateY(167%);
  }
}

@media (max-width: 767px) {
  .count-down {
    transform: translateY(240%);
  }
}

.count-down-fixed {
  transform: translateY(0%);
}

@media (min-width: 768px) {
  .count-down-stacked {
    transform: translateY(167%);
  }
}

@media (max-width: 767px) {
  .count-down-stacked {
    transform: translateY(240%);
  }
}

.modal-save-btn {
  width: 100%;
}

.py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.py-16-24 {
  padding-top: 16px !important;
  padding-bottom: 24px !important;
}

@media (max-width: 767px) {
  .mt-22 {
    margin-top: 22px !important;
  }
}

.f1-ticket-scroll {
  overflow-y: scroll;
  height: 512px;
}

@media (min-width: 768px) {
  .btn-weight {
    min-width: 508px !important;;
  }
}

@media (max-width: 767px) {
  .btn-weight {
    min-width: 343px !important;
    ;
  }
}



@font-face {
  font-family: 'Formula1-TV';
  src: url('/static/fonts/f1Fonts/Formula1-TV-600_Medium_web.woff') format("woff"),
  url('/static/fonts/f1Fonts/Formula1-TV-600_Medium_web.woff2') format("woff2");
}
.f1-font{
  font-family: Formula1-TV;
}

.f1-background-color{
  background: #CF2A19 !important;
}

.f1-color {
  color: #CF2A19 !important;
}

.btn-f1 {
  background-color: #CF2A19;
  color: #ffffff;
  border-color: #CF2A19;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); }
  .btn-f1:focus, .btn-f1:hover {
    color: #ffffff;
    background-color: #f46252;
    border-color: #f46252; }

.btn-f1-disable{
  background-color: #F2F4F7 !important;
  color: #98A2B3 !important;
  border-color: #F2F4F7 !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.btn-outline-f1 {
  color: #CF2A19;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none; }
  .btn-outline-f1:focus, .btn-outline-f1:hover {
    background-color: #f46252;
    color: #ffffff;
        border-color: #f46252; }

.card-icon--normal .card-title-f1{
  color: #CF2A19;
  margin-bottom: 0.75rem;
}

.timeline-f1:after{
    background: #CF2A19 ;
}

.timeline-f1 .timeline-event:after{
  background: #CF2A19 ;
}

.timeline-f1 .timeline-event:before{
  color: #CF2A19 ;
}
@media (min-width: 768px){
  .timeline-f1 .timeline-event:before {
      color: #1D2939 }
}

.hide-color {
  text-decoration: none !important;
}

.card-icon--normal .card-title-f1 .card-icon{
  font-size: 18px;
  margin-right: 0.75rem;
}



/* check out page */
.card-body-1 {
  border-radius: 0;
  box-shadow: none;
  padding: 1.5rem 20px;
  border-bottom: 1px solid #F2F4F7;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
}

/* order status */
.status-badge-error-2 {
  color: #B42318;
  background-color: #FEF3F2;
}

/* Remove excess white space */
.text-empty {
  margin-bottom: 5.75rem !important;
}
/* .text-extend {
  margin-top: 100px;
}
.text-extend-plus {
  margin-top: 300px;
} */

/* order detail (Pay For Yourself or a Friend) */
.px-md-67 {
  flex-wrap: wrap-reverse !important;
}
.alert-margin {
  margin: auto;
}

.sign-margin {
  margin-left: 58.4px;}
  @media (max-width: 407px){
    .sign-margin{
    margin-left:31.4px;}
  }
.sign-mobile-margin{
  margin-left: 58.4px;
}

/* layaway style */
.layaway-modal-margin-pc .modal-dialog {
  margin-top: 63px;
}
.layaway-modal-radius .modal-content {
  border-radius: 0.75rem;
}
.f1-map-font{
  font-size: "24px";
  color: "white";
  cursor: "pointer";
  position: "absolute";
}


.fw-600{
  font-weight: 600 !important;
}

.card-mb {
  font-size: 14px !important;
  line-height: 20px !important;
}

.status-upcoming{
  color: #B54708;
  background-color: #FFFAEB; }

.btn-mb-review{
  margin-bottom: 34px; }

@media (max-width: 767px) {
  .breadcrumb-mobile-interval {
    padding-bottom: 16px !important;
    padding-left: 11.5px; }

  .paragrah-p-interval {
    padding-left: 16px !important;
    padding-right: 16px !important; }

  .text-dark-mobile{
    color: #1d2939 !important; }

  .pt-28-mobile{
    padding-top: 28px !important; }

  .text-gray700-mobile{
    color: #344054 !important; }

  .pb-41-mobile{
    padding-bottom: 41px !important; }

  .pt-2-mobile{
    padding-top: 2rem !important; }

  #shipping-address .fs-0 {
    padding-bottom: 1rem !important; }

  .checkout-pd-top{
    padding-top: 1.5rem !important; }

  .btn-mb-review{
    margin-bottom: 20px !important;
  }

  .checkout-pd-top #payment-info{
    margin-top: 0 !important;
  }
}

@media (min-width: 768px) {
  .split-modal--payment-m-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .mb-46{
    margin-bottom: 46px !important; }
}
.pt-40 {
  padding-top: 40px !important; }

.pt-32 {
  padding-top: 32px !important; }

@media (max-width: 767px) {
  .mt-183 {
    margin-top: 183px !important;
  }
}


.split-close-confirm-head{
  color:"#344054" !important;
  line-height:"28px" !important;
}

.split-addfriend-button{
  color: "#175CD3" !important;
  font-family: "Inter" !important;
  font-style: "normal" !important;
  padding-top: "20px" !important;
  padding-left: "8px" !important;
  font-weight: "400" !important; }

.rounded-white-border {
  border: 8px solid white;
  background: white;
  border-radius: 100%;
  color: white;
}

.end-2-5 {
  right: 2.5rem !important;
}

.btn-f1-gray {
  color: #344054;
  background-color: white;
  border-color: #D0D5DD;
  box-shadow: none;
}

.btn-f1-gray:hover {
  color: #ffffff;
  background-color: #f46252;
  border-color: #f46252;
}

.btn-f1-gray:focus {
  color: #ffffff;
  background-color: #EF3E22;
  border-color: #EF3E22;
}

.btn-purple-hero {
  min-width: 258px;
  padding: 10px 14px;
  background-color: #7F56D9;
  color: #ffffff;
  border-color: #7F56D9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

.btn-purple-hero:hover {
  background-color: #9E77ED;
  border-color: #9E77ED;
  color: #ffffff;
}

.f1-event-page .breadcrumb .breadcrumb-item a:hover {
  text-decoration: underline;
  color: #f46252;
}

.breadcrumb .breadcrumb-item a:hover {
  text-decoration: underline;
  color: #6941C6;
}

.text-purple:hover {
  color: #7F56D9;
}

.mb-md-6 {
  font-weight: 600;
}

.f1-map-overflow{
  overflow:hidden;
  position: relative;
  height: 100%;
}

.f1map-tooltip-title{
  font-family:"Formula1-TV";
  font-weight: 475;
  font-size:18px;
  line-height:27px;
}

.flmap-tooltip-content{
  font-size: 13.5px;
  font-weight: 500;
  line-height: 20px;
  color: #475467;
}

/* guests modal */
.css-tlfecz-indicatorContainer svg {
  color: #667085;
}

.readMore-showLess {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6941C6;
  text-decoration: none; }
.pb-px-12{
  padding-bottom: 12px;
}

.order-total-font{
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #344054; }

.f1-border-color {
  border: 2px solid rgb(242 195 190) !important;
}

.down-arrow{
  cursor: pointer;
}

.header-hidden-text{
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:3;
}

.zindex2{
  z-index: 2 !important;
}
.tooltip-slug .tooltip-arrow {
  margin-left: -40px;
}

@media (max-width: 991px){
  .f1-mission-container {
    margin-bottom: 60px;
  }
}

.text-dark-important{
  color: #101828 !important;
}
.mb-27 {
  margin-bottom: 27px !important;
}

.mx-20-23 {
  padding-top: 20px !important;
  padding-bottom: 23px !important;
}

@media(min-width: 768px) {
  .adds-on-btnwrapper {
    margin-bottom: 150px !important;
  }
}

@media(max-width:767px) {
  .adds-on-content {
    padding-top: 0px !important;
    padding-bottom: 20px !important;
  }
}

@media(min-width:768px) {
  .adds-on-content {
    padding-top: 49px !important;
  }
}

.mouse-default{
  cursor: default !important;
}

/* accept page */
.accept .ticket-para-textWrapper .reset-mb-75 {
  margin-bottom: 0.75rem;
}

.footer-event-container-new {
  margin-bottom: 60px;
}

@Media (min-width: 768px) {
  .footer-event-container-new {
    margin-bottom: 100px;
    }
}
.presale-warning-icon {
  left: 450px;
  width: 12px;
  bottom: 30px;
  position: absolute;
}
@Media (max-width: 768px) {
  .presale-warning-icon {
    left: 280px;
  }
}
.event-detail-wrapper .readMore-showLess{
  color: #7F56D9;
  font-size: inherit;
  line-height: inherit;
}

.modal-open{
  padding-right: 0px !important;
}

.mb-70{
  margin-bottom: 70px !important;
}

.room-selection .re-modal-dialog {
  max-width: 540px;
}
.btn-gray {
  background-color: #F2F4F7;
  border: 1px solid;
  border-color: #F2F4F7;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #98A2B3;;
}



@media (min-width: 768px) {
  .card-selectHotel {
    padding: 1.5rem;
  }
  .container-mb{
    margin-bottom:3rem !important;
    padding-bottom: 161px !important;
  }
}

@media (max-width: 767px) {
  .container-mb{
    margin-bottom: 133px !important;
  }
}
.isNotFuture {
  display: none;
}

.presale-btn {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .footer-email-panel .form-control::-ms-input-placeholder {
    text-align: center;
  }
   .footer-email-panel .form-control::-webkit-input-placeholder {
    text-align: center;
  }
}

/*index Event Load More */
.loading-events {
  margin-top: 70px;
}

@media(max-width:767px) {
  .loading-events {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.loading-btn {
  margin-bottom: 50px;
}

.room-header-tips {
  margin-bottom: 110px;
}

@media(max-width:767px) {
  .room-header-tips {
    margin-bottom: 15% !important;
  }
}

.mb-68{
  margin-bottom: 68px !important;
}

.mb-20{
  margin-bottom: 20px !important;
}

.mb-8{
  margin-bottom: 8px !important;
}

.addon-color {
  color: #6941C6;
}

@media (max-width: 767px) {
  .email-panel .form-control::-ms-input-placeholder {
    text-align: center;
  }
   .email-panel .form-control::-webkit-input-placeholder {
    text-align: center;
  }
}
.room-names-tips {
  font-weight: 600;
}

.room-header-tips-pd {
  padding: 2rem;
  padding-bottom: 0.5rem;
}

.room-body-tips-pd {
  padding-bottom: 24px !important;
  padding-top: 0px !important;
}
/* image-gallery */
.image-gallery-allcards {
  width: 522px;
  height: 356px;
  margin: 0 auto 12px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .image-gallery-allcards {
    width: 100%;
    height: 0;
    padding-bottom: 65%;
  }
}

.image-gallery-container {
  height: 100%;
  overflow: auto;
  width: 100%;
  margin: auto;
  padding-top: 104px;
  padding-bottom: 90px;
}

@media (max-width: 767px) {
  .image-gallery-container {
    margin-top: 60px;
    padding: 0 10% 90px 10%;

  }
}

.close-image-gallery-btn {
  right: 80px;
  top: 24px;
  width: 64px;
  height: 64px;
  padding: 0;
}

@media (max-width: 769px) {
  .close-image-gallery-btn {
    right: 1%;
    top: 24px;
    width: 40px;
    height: 40px;
    padding: 0;
  }
}

.hotel-list-container .hotel-item-wrapper .slick-prev.slick-disabled, .hotel-list-container .hotel-item-wrapper .slick-next.slick-disabled {
  opacity: 0.2;
  cursor: default;
}

@media (min-width: 767px) {
  .hotel-room-image-card .image-gallery-content .image-gallery-slide .image-gallery-image {
    width: 740px;
  }
}

.CalendarDay__selected {
  background: #7F56D9 !important;
}
.chooseHotel-mt {
  margin-top: 40px !important;
}
@media (max-width: 767px) {
  .chooseHotel-mt {
    margin-top: 32px !important;
  }
}

.tipHide {
  max-height: 250px;
  display: -webkit-box;
  /* -webkit-line-clamp: 10; */
  -webkit-box-orient: vertical;
  overflow: hidden;
}


.breadcrumbs-navbar-click {
  cursor:pointer;
  pointer-events: none;
  color: #98a2b3;
}

.breadcrumbs-navbar-arrows {
  pointer-events: none;
}
.breadcrumb .breadcrumb-item-navbar a:hover {
  text-decoration: none;
}

@media(max-width: 767px) {
  .breadcrumb-mb {
    margin-bottom: 4px;
  }
}
.chooseHotel-navbar-mt {
  margin-top: 0px !important;
}

@media (max-width: 767px) {
  .chooseHotel-navbar-mt {
    margin-top: 0px !important;
  }
}

@media (min-width: 768px) {
  .adds-on-content-0 {
    padding-top: 0px !important;
  }
}

@media (min-width: 768px) {
  .containers-review-border {
    border-radius: 12px;
  }
}

.fontSize14 {
  font-size: 14px
}

.btn-outline-gray-notHover {
  color: #344054;
  background-color: white;
  border-color: #D0D5DD;
  box-shadow: none;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
.no-touch .btn-outline-gray-notHover:hover{
  color: #1D2939;
  background: #F9FAFB;
}

@media (max-width: 767px) {
  .order-detail-breadcrumb-mobile {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 23px;
    margin-bottom: 12px;
  }
}

.need-more-time-header {
  color: #1D2939;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}
.schedule-price-ml {
  margin-left: -1.5rem !important;
}
@media (min-width: 375px) {
  .schedule-price-ml {
    margin-left: 1.25rem !important;
  }
}

.btn-tour-event-soldout {
  width: 133.14px;
}
.addons-pop {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .addons-pop-modal {
    max-height: 700px !important;
    height: 700px;
  }
}

.addons-item-pricing {
  font-size: 14px;
}

.btn-purple51 {
  color: #6941C6;
  background-color: #F9F5FF;
  border-color: #F9F5FF;
}

.btn-purple51:hover {
  color: #6941C6;
  background-color: #F4EBFF;
  border-color: #F4EBFF;
}

.tour-event-soldout-mb{
  white-space: nowrap;
  color: #667085;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .review-fs-lh {
    font-size: 16px !important;
    line-height: 1.5 !important;
  }
}

@media (max-width: 767px) {
  .preview-btn-pb {
    padding-bottom: 1rem !important;
  }
}

.btn-continue-mb {
  margin-bottom: 0.75rem;
}

@media (max-width: 767px) {
  .btn-continue-mb {
    margin-bottom: 1.5rem;
  }
}

.submit-btn-mb {
  margin-bottom: 24px;
}

.p-submit-btn-mb {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .btn-mb-review-32 {
    margin-bottom: 32px !important;
  }
}

@media (max-width: 767px) {
  .pb-3-continue-btn {
    margin-bottom: 1rem !important;
  }
}
.ml-30 {
  margin-left: 30px;
}
.ml-21 {
  margin-left: 21px;
}

@media (max-width: 767px) {
  .ml-10 {
    margin-left: 10px !important;
  }
}
@media (max-width: 767px) {
  .ml-1 {
    margin-left: 1px !important;
  }
  .ml-6 {
    margin-left: 6px;
  }
}

.container-map-popup {
  max-width: 1000px !important;
  width: 100%;
  position: relative;
}

.thumbnail-bg {
  background-color: white !important;
}

.update-email-pt {
  text-align: center;
  padding-top: 24px;
}
.input-width-408 {
  width: 408px;
}

.footer-Mobile {
  padding: 16px 32px 24px;
}

.height-54 {
  height: 54px;
}

.ticketDescription-ViewMore {
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}

.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.mb-44 {
  margin-bottom: 44px;
}

.border-bottom-gray200 {
  border-bottom: 1px solid #E4E7EC;
}

.my-26 {
  margin-top: 26px;
  margin-bottom: 26px;
}

.presaleTicket-btn {
  padding: 12px 0px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .presaleTicket-btn {
    font-size: 14px;
    line-height: 20px;
  }
}

.zindex9 {
  z-index: 9 !important;
}

.braintree-placeholder {
  display: none
}

.braintree-form__field-group>label {
  display: block
}

.zIndex1200 {
  z-index: 1200;
}

.overFlow-y-roomPage {
  overflow-y: auto !important;
}

.image-gallery .image-gallery-content .image-gallery-center {
  position: relative;
}