/* .modal::-webkit-scrollbar, .modal-body::-webkit-scrollbar, .overlay-wrapper::-webkit-scrollbar {
  display: none;
}

.modal, .modal-body, .overlay-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */
.modal {
  background: rgba(29, 41, 57, 0.5);
}

.modal .offcanvas {
  position: static;
}

.modal .button-close .fa-times {
  margin: auto;
}

@media (min-width: 992px) {

  .modal .overlay-wrapper {
    padding-top: 3rem;
  }
  .modal .modal-text-lg-left {
    text-align: left !important;
  }
}

@media (min-width: 992px) {

  .modal .overlay-wrapper .button-close {
    width: 40px;
    height: 40px;
    top: 16px;
    right: 8px;
  }

  .modal .overlay-wrapper .button-close .fa-times {
    font-size: 24px;
  }

  .modal .event-info-container {
    margin-bottom: 0;
  }

  .modal .text-lg-left {
    text-align: left !important;
  }
}